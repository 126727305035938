// import { getCurrentUser } from '../helpers/Utils';
import { odoo_url } from '../environment/environment';

export default async function sendCredentials(username, password) {
    try {
    var formdata = new FormData();
    formdata.append('email', username);
    formdata.append('password', password);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };

    const response = await fetch(`${odoo_url}/api/login`, requestOptions);
    const data = await response.text();
    return data;
  } catch (error) {
    console.log('error', error);
    return { code: 'error', message: 'An error occurred.' };
  }
}
